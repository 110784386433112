import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { PickersActionBar } from '@mui/x-date-pickers-pro';
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'

export const CustomSelectionFilter = ({ applyValue, item, options, type, value }) => {

    const { t } = useTranslation()

    const { from, to } = value || {}

    const [range, setRange] = useState([from && dayjs(from), to && dayjs(to)])

    return (
        (type === 'date' || type === 'datetime')
            ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePicker

                        closeOnSelect={false}
                        format="DD/MM/YYYY"
                        label={t('Select Filter')}
                        onAccept={(values) => {

                            const from = values[0] ? values[0].format('YYYY-MM-DD') : null;
                            const to = values[1] ? values[1].format('YYYY-MM-DD') : null;
                            if (!from && !to) {
                                applyValue({
                                    ...item,
                                    // operator: 'between', 
                                    value: {
                                        from:null,
                                        to:null,
                                    }
                                })
                            } else {
                                const value = from || to ? {} : null;
                                if (from) value.from = from;
                                if (to) value.to = to;

                                applyValue({
                                    ...item,
                                    // operator: 'between', 
                                    value
                                })
                            }
                            setRange(values)
                        }}
                        slotProps={{
                            textField: {
                                placeholder: '',
                                variant: 'filled',
                                sx: {
                                    width: 'inherit',
                                    "& .MuiInputBase-input": {
                                        padding: '4px 0 5px',
                                    },
                                    "& .MuiInputLabel-root": {
                                        top: '-15px !important', // todo 15 senza valore, 18 con valore
                                        left: '-11px !important',
                                        "&.Mui-focused": {
                                            top: '-18px !important'
                                        },
                                    },
                                },
                            },
                            actionBar: {
                                actions: [
                                    'accept',
                                    'cancel',
                                    'clear',
                                    // 'today',
                                ],
                            },
                        }}
                        slots={{
                            field: SingleInputDateRangeField,
                            actionBar: PickersActionBar,
                        }}
                        value={range}
                        
                    />
                </LocalizationProvider>
            )
            : (
                <FormControl sx={{ width: 'inherit' }} variant="standard">
                    <InputLabel>{t('Select Filter')}</InputLabel>
                    <Select onChange={(event) => applyValue({ ...item, value: event.target.value })} value={value}>
                        <MenuItem value="">
                            {t('No selection')}
                        </MenuItem>
                        {options.map((option, index) => <MenuItem key={option + index} value={option}>{t(option)}</MenuItem>)}
                    </Select>
                </FormControl>
            )
    )
}
