import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { Tag, PersonAvatar } from '../Snippet';
import { useTranslation } from 'react-i18next'
import { useFormatter } from '../../utils';
import { Icon } from 'src/mtska-frontend-app-component/assets';

const RelationshipPool = ({ k, v, editing, detailInspector = true, handleItemDetail, handleItemRemove }) => {
    const { t, i18n } = useTranslation();
    const { formatDate } = useFormatter();

    const tagcolors = {
        active: "success",
        terminated: "warning",
        future: "secondary",
        missing_timeframe: "danger"
    }

    return (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            {/* <textarea>{JSON.stringify(v)}</textarea> */}
            <ListItemContent sx={{ display: 'flex' }} className={"relationship-element-content"}>
                <Box className={"coupled"}>
                    <Box className="value">
                        <PersonAvatar firstname={v.employment?.person?.firstname} lastname={v.employment?.person?.lastname} size="small" url={v.employment?.person?.picture?.url} withname={ true } />
                    </Box>
                </Box>
                <Box className={"coupled"}>
                    <Box className="label">{t('car')}</Box>
                    <Box className="value">
                        <span className="plate">{v.car?.plate ? v.car?.plate : <Tag color={"danger"} title={t("Missing")}>{t('Missing plate')}</Tag>}</span>
                    </Box>
                </Box>
                <Box className={"status"}>
                    <Tag color={(tagcolors[v?.status ? v?.status : 'missing_timeframe'])} size="small" label={t(v?.status ? v?.status : 'missing_timeframe')} />
                </Box>
                <Box className={"coupled"}>
                    <Box className="label"><Icon icon="faCalendarHeart" /></Box>
                    <Box className="value">
                        <span>{formatDate(v.assignment_start_at)}</span>
                    </Box>
                </Box>
                <Box className={"coupled"}>
                    <Box className="label"><Icon icon="faCalendarXmark" /></Box>
                    <Box className="value">
                        <span>{formatDate(v.assignment_end_at)}</span>
                    </Box>
                </Box>
            </ListItemContent>
            {editing && detailInspector && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipPool;



