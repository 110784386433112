import React, { useEffect } from 'react'
import { Box, List, ListItem } from '@mui/joy';

import { useTranslation } from 'react-i18next';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { DrivingLicenceAvatar, PersonAvatar, useDrawer, VehicleCard } from 'src/mtska-frontend-app-component';

const Default = () => {
    const { t } = useTranslation();
    const { activate } = useDrawer();

    const { item: user } = useDataProvider('auth/token');
    const { loadAll: loadAllMyPersons, items: myPersons, total: myPersonsTotal, reloadCounter: myPersonsReloadCounter } = useDataProvider('persons/person');
    const { loadAll: loadAllMyCars, items: myCars, total: myCarsTotal, reloadCounter: myCarsReloadCounter } = useDataProvider('cars/car');

    useEffect(() => {
        loadAllMyPersons({
            items: [{
                field: "email",
                operator: "equals",
                value: user?.data?.email
            }]
        });
        loadAllMyCars();
    }, [user]);

    const handlePersonClick = () => {
        activate('persons/detail', { id: myPersons[0].id });
    }

    return (
        <Box className="dashboard dashboard-driver">
            <Box onClick={handlePersonClick}>
                <Box className="dashboard-portion-title">{t('Welcome')}</Box>
                <PersonAvatar firstname={myPersons[0]?.firstname} lastname={myPersons[0]?.lastname} url={myPersons[0]?.picture?.url} withname={true} detail={user?.data?.email} />
                <DrivingLicenceAvatar person={myPersons[0]} />
            </Box>

            <Box className="dashboard-portion-title">{t('Your vehicles')}</Box>
            <Box className="dashboard-portion-subtitle">{t('You have {{myCarsTotal}} vehicles in your garage', { myCarsTotal: myCarsTotal })}</Box>

            <List className="dashboard-portion dashboard-menu">
                {
                    myCars.map((myCar, k) => (
                        <ListItem key={k} className="dashboard-portion-item">
                            <VehicleCard item={myCar} />
                        </ListItem>
                    ))
                }
            </List>
        </Box>
    )

}

export default Default;


