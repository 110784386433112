import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'persons/person';
const model = 'persons/person';
const modelExposedName = 'Person';
const wizardAction = 'create';

const Create = (params) => {

    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);;


    // DATA PROVIDER
    const {
        doAction,
        item,
        loadOne,
        loadAll,
        errorMessage,
        errors,
        saving,
        success
    } = useDataProvider(dataProviderName, params.multipart);


    const modelName = existingModules[dataProviderName].modelName
    const detailConfig = {
        "tabs": [
            {
                "name": "create_new_person",
                "fieldsets": [
                    {
                        "name": "create_new_person",
                        "fields": {
                            "personal_information": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "firstname": {
                                "type": "text",
                                "required": "true",
                                "className": "col-6"
                            },
                            "lastname": {
                                "type": "text",
                                "required": "true",
                                "className": "col-6"
                            },
                            "email": {
                                "type": "text",
                                "required": "true",
                                "className": "col-6"
                            },
                            "fiscalcode": {
                                "type": "text",
                                "required": "true",
                                "className": "col-6"
                            },
                            "legal_entity_information": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "legalEntity": {
                                "type": "relationshipSingle",
                                "relation": {
                                    "drawerRoute": "legalentities",
                                    "inverseRelation": "person",
                                    "inverseRelationType": "relationship",
                                    "filter": {
                                        "items": [{
                                            "field": "type",
                                            "operator": "in",
                                            "value": ["customer", "partner"],
                                        }]
                                    },
                                    "limit": 1000
                                },
                                "view": "legalEntity",
                                "required": "true"
                            },
                            "employments.role": {
                                "type": "text",
                                "required": "true",
                                "className": "col-12"
                            }
                        }
                    }
                ]
            }
        ],
        "validation": []
    }

    useEffect(() => {
        loadOne(params.filters?._id);
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])



    const handleSave = async (data) => {
        doAction && doAction({ action: wizardAction, model: model ? model : dataProviderName, data: data });
    }

    return <>
        <DetailComponent
            values={{
                ...item?.data,
            }}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('New {{itemname}}', { itemname: t(modelExposedName) })}
            canSave={userCapabilities && userCapabilities[dataProviderName + '.create']}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Create new {{itemname}}', { itemname: t(modelExposedName) })}
                    subtitle={t('Fill the basic information to create a new {{itemname}} that you will be able to edit via the standard interface', { itemname: t(modelExposedName) })}
                />
            }
            {...params}
        />
    </>
}

export default Create;


