import React from 'react';
import { Box } from '@mui/joy';
import { useFormatter } from '../../utils';

const CellFilesize = ({ value, type }) => {
    const { formatBytes } = useFormatter();
    
    let size;
    if (value && value != undefined && value != 0) {
        try {
            size = formatBytes(value / 1024);
        } catch (e) {
            size = '!'+value;
        }
    } else if (value == 0) {
        size = formatBytes(value);
    } else {
        size = '';
    }

    return <Box className={"list-element-cell cell-" + type}>{ size }</Box>
}

export default CellFilesize