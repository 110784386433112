//// LOCAL
import './mtska-frontend-app-scaffolding/styles/App/App.scss';
import { App } from './mtska-frontend-app-scaffolding';
import { DataProvider } from "src/mtska-frontend-data-provider";

const AppExecutive = () => {
    return (
        <DataProvider>
            {/* {process.env.REACT_APP_API_ENDPOINT && (
                <div style={{backgroundColor: "red", color:"white"}}>Custom endpoint: {process.env.REACT_APP_API_ENDPOINT}</div>
            )} */}
            <App />
        </DataProvider>
    )
}

export default AppExecutive;
