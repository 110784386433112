import React, { useContext } from "react"
import { ActiveModuleName, UserMenu } from '../../../mtska-frontend-app-component'
import { AppContext } from '../../contexts'
import { useTranslation } from 'react-i18next'
import { HamburgerMenu } from "src/mtska-frontend-app-component/components/Menu/HamburgerMenu"
import { Box } from '@mui/joy'

const Header = () => {

    const { t } = useTranslation()

    const { currentModuleName, currentModuleScope, appConfiguration, innerWidth } = useContext(AppContext)

    document.title = t(appConfiguration.app.pageTitle, { cmn: t(currentModuleName) })

    return (
        <header>
            <ActiveModuleName currentModuleName={currentModuleName} currentModuleScope={currentModuleScope} logo={appConfiguration.app.logoSvg} />
            <div className="filler"></div>
            {/* <div>{JSON.stringify(appConfiguration)}</div> */}
            {innerWidth <= 600 && (
                <Box sx={{ alignContent: 'center' }}>
                    <HamburgerMenu renderContent={() => <UserMenu />} />
                </Box>
            )}
        </header>
    )
}

export default Header
