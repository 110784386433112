import { useState, useEffect } from 'react'

export const useWindowDimensions = () => {

    const [innerHeight, setInnerHeight] = useState(0)
    const [innerWidth, setInnerWidth] = useState(0)

    const handleResize = () => {
        const { innerHeight, innerWidth } = window
        setInnerHeight(innerHeight)
        setInnerWidth(innerWidth)
    }

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return { innerHeight, innerWidth }
}
