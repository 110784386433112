import React, { useMemo, useState, useContext } from 'react'
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { DefaultStandard, DefaultSimplified } from './';

const dataProviderName = 'cars/car';
const drawerRouteBase = 'cars';

const Default = () => {
    const { userCapabilities } = useContext(AppContext);

    return (userCapabilities && userCapabilities[dataProviderName + '.seeonlymine']) ? (
        <DefaultSimplified />
    ) : (
        <DefaultStandard />
    )

}

export default Default;


