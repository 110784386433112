import React, { useState, useEffect } from 'react';

const AppContext = React.createContext({
    currentModuleName: 'noname',
    setCurrentModuleName: () => { },
    setCurrentModuleScope: () => { },
    currentModuleNavigationData: [],
    setCurrentModuleNavigationData: () => { },
    filterByLegalEntity: 0,
    userCapabilities:[],
    setUserCapabilities: () => { },
    appConfiguration:[],
    setAppConfiguration: () => { },
    activeModules:[],
    setActiveModules: () => { },
    setFilterByLegalEntity: () => { },
});

export const AppContextProvider = ({ children, concreteconfig }) => {
    const [currentModuleName, setCurrentModuleName] = useState();
    const [currentModuleScope, setCurrentModuleScope] = useState();
    const [currentModuleNavigationData, setCurrentModuleNavigationData] = useState();
    const [filterByLegalEntity, setFilterByLegalEntity] = useState(sessionStorage.getItem('filterByLegalEntity'));

    const config = {
        currentModuleName,
        setCurrentModuleName,
        currentModuleScope,
        setCurrentModuleScope,
        currentModuleNavigationData,
        setCurrentModuleNavigationData,
        filterByLegalEntity,
        setFilterByLegalEntity,
        ...concreteconfig
    }

    return (
        <AppContext.Provider value={config}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;