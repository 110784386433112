import React from 'react';
import { Box } from '@mui/joy';
import { useFormatter } from '../../utils';

const CellDatetime = ({ value, type }) => {
    const { formatDatetime } = useFormatter();
    
    let date;
    if (value && value !== undefined) {
        try {
            date = formatDatetime(value);
        } catch (e) {
            date = '';
        }
    } else {
        date = '';
    }

    return <Box className={"list-element-cell cell-" + type}>{ date }</Box>
}

export default CellDatetime