import React, { useEffect, useState } from "react";
import { useDataProvider } from "src/mtska-frontend-data-provider";
import { Box, Table } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import { Text } from "../Typography";
import { Button } from "../Button";

const ImportData = ({ importdataid }) => {
    const { t } = useTranslation();
    const { item: dataItem, loadOne: loadOneData } = useDataProvider('imports/data')
    const { item: jobItem, loadOne: loadOneJob } = useDataProvider('imports/job')

    const [isDrawable, setIsDrawable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [jobVisible, setJobVisible] = useState(false);
    const [rowVisible, setRowVisible] = useState(false);
    const [importFileName, setImportFileName] = useState('');
    const [importFileRow, setImportFileRow] = useState('');

    useEffect(() => {
        if (isLoading) return;

        setIsLoading(true);
        loadOneData(importdataid).then(() => {
            setImportFileName(dataItem?.data?.import_file);
            setImportFileRow(dataItem?.data?.import_row_id);
            setIsDrawable(true)
        });
    }, [importdataid]);

    const loadData = () => {
        if (!jobItem) {
            loadOneData(importdataid).then(() => {
                setRowVisible(true);
            })
        } else {
            setRowVisible(!rowVisible);
        }
    }

    const loadJob = () => {
        if (!jobVisible) {
            loadOneJob(dataItem?.data?.job_id).then(() => {
                setJobVisible(true);
            })
        } else {
            setJobVisible(!jobVisible);
        }
    }

    return (
        <Box className="import-debugger-wrapper">
            <Text className="title">{t('Import information')}:</Text>
            <Text className="informations"><span className="filename">{importFileName}</span> <span className="rowid">{importFileRow}</span></Text>
            {isDrawable && (
                <Box className="import-debugger import-job-debugger">
                    <div class="import-debugger-header">
                        <span>{t('Job data')}</span>
                        <Button customSize="mini" onClick={() => loadJob()}>{jobVisible ? t('Hide') : t('Show')}</Button>
                    </div>
                    {
                        jobVisible && <JSONPretty data={jobItem} className="json-wrapper"></JSONPretty>
                    }
                </Box>
            )}
            {isDrawable && (
                <Box className="import-debugger import-data-debugger">
                    <div class="import-debugger-header">
                        <span>{t('Row data')}</span>
                        <Button customSize="mini" onClick={() => loadData()}>{rowVisible ? t('Hide') : t('Show')}</Button>
                    </div>
                    {
                        rowVisible && <JSONPretty data={dataItem} className="json-wrapper"></JSONPretty>
                    }
                </Box>
            )}
        </Box>
    );

}

export default ImportData;