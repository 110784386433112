import React, {useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/car';
const wizardName = 'Plate management';
const wizardAction = 'plateManagement';
const wizardObject = 'Plate';


const PlateManagement = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);


    // DATA PROVIDER
    const { 
        item, 
        errorMessage, 
        errors, 
        saving, 
        doAction,
    } = useDataProvider(dataProviderName, params.multipart);

    const modelName = existingModules.modelName

    const detailConfig = {
        "tabs": [
            {
                "name": "management",
                "fieldsets": [
                    {
                        "name": "management",
                        "fields": {
                            "plate": {
                                "type": "text",
                                "className": "col-12",
                                "required": true,
                                "placeholder": "insert",
                                "icon": "faInputNumeric"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }
        
    const handleSave = (data) => {
        doAction({action: wizardAction, model: dataProviderName, data: data}).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    }

    return <>
    {/* <span>PARENT</span>
    <textarea>{JSON.stringify(params?.parent)}</textarea>
    <span>ITEM</span>
    <textarea>{JSON.stringify(item?.data)}</textarea> */}
        <DetailComponent
            values={{ "id" : item?.data?._id, "plate": item?.data?.plate}}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.'+wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Define a {{wizardObject}} to be associated to {{carIdentifier}}', { wizardObject:wizardObject, carIdentifier: (item.data.plate ? item.data.plate : item.data.setup?.make+" "+item.data.setup?.model) })} 
                />
            }
            {...params}
        />
    </>
}

export default PlateManagement;


