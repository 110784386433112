import React, {useMemo} from 'react';
import { Box } from '@mui/joy';
import { KPI } from '../../../../mtska-frontend-app-component';
import List from './List';

const Default = () => {

    return useMemo(() => <>
        <Box className="mtksa-chart-wrapper">
            <KPI
                title="Number of mileage records"
                icon="faTally"
                dataProviderName={'statistics/item/count/cars/traceKm'}
            />
            <KPI
                title="Vehicles"
                icon="faCar"
                dataProviderName={'statistics/item/count/cars/car'}
            />
        </Box>
        <List />
    </>,[])

}

export default Default;


