import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';


const apiBasePath =  process.env.REACT_APP_API_ENDPOINT || window.location.origin + '/api';
i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
        backend: {
            loadPath: apiBasePath + '/{{ns}}/{{lng}}',
            saveMissing: true,
            addPath: '/locales/add/{{lng}}/{{ns}}',

        },
        debug: true,
        fallbackLng: 'it',
        interpolation: {
            escapeValue: false,
        },
    })

export default i18next;

// https://react.i18next.com/