import React, {useMemo} from 'react';
import { Box } from '@mui/joy';
import { KPI } from '../../../../mtska-frontend-app-component';
import List from './List';

const Default = () => {

    return useMemo(() => <>
        <Box className="mtksa-chart-wrapper">
            <KPI
                title="assignments"
                icon="faPerson"
                dataProviderName={'statistics/item/count/cars/pool'}
            />
            <KPI
                title="Active drivers"
                icon="faPeople"
                dataProviderName={'statistics/active/count/cars/pool'}
                filters={{
                    'items': [
                        {
                            'field': 'assignment_start_at',
                            'operator': 'before',
                            'value': new Date().toISOString()
                        },
                        {
                            'field': 'assignment_end_at',
                            'operator': 'afterOrNull',
                            'value': new Date().toISOString()
                        }
                    ]
                }}
            />
            <KPI
                title="Vehicles"
                icon="faCar"
                dataProviderName={'statistics/item/count/cars/car'}
            />
        </Box>
        <List />
    </>,[])

}

export default Default;


