import { CustomSelectionFilter } from "./customSelectionFilter/CustomSelectionFilter"

export const getCustomFilterOperators = ({ options, t, type, value }) => (
    {
        InputComponent: (params) => <CustomSelectionFilter {...params} options={options} type={type} value={value} />,
        label: t('Select Filter'),
        value: 'equals'
    }
)

export const getCustomLocaleText = ({ t }) => {
    return {
        headerFilterOperatorContains: t('Contains'),
        headerFilterOperatorDoesNotContain: t('Does not contain'),
        headerFilterOperatorEquals: t('Equals'),
        headerFilterOperatorDoesNotEqual: t('Does not equal'),
        headerFilterOperatorStartsWith: t('Starts with'),
        headerFilterOperatorEndsWith: t('Ends with'),
        headerFilterOperatorIs: t('Is'),
        headerFilterOperatorNot: t('Is not'),
        headerFilterOperatorAfter: t('Is after'),
        headerFilterOperatorOnOrAfter: t('Is on or after'),
        headerFilterOperatorBefore: t('Is before'),
        headerFilterOperatorOnOrBefore: t('Is on or before'),
        headerFilterOperatorIsEmpty: t('Is empty'),
        headerFilterOperatorIsNotEmpty: t('Is not empty'),
        headerFilterOperatorIsAnyOf: t('Is any of'),
        headerFilterOperatorSelectFilter: t('Select Filter'),
        'headerFilterOperator=': t('Equals'),
        'headerFilterOperator!=': t('Not equals'),
        'headerFilterOperator>': t('Greater than'),
        'headerFilterOperator>=': t('Greater than or equal to'),
        'headerFilterOperator<': t('Less than'),
        'headerFilterOperator<=': t('Less than or equal to')
    }
}

export const moveArrayElement = (array, fromIndex, toIndex) => {
    const element = array[fromIndex]
    array.splice(fromIndex, 1)
    array.splice(toIndex, 0, element)
    return array
}
