import React, { useMemo, useState, useEffect } from 'react'
import { Box } from '@mui/joy';

const Calculator = () => {

    const tabellaVeicoli = [
        {
            "joint_model": "Alfa Romeo Tonale 1.6 TDI 130CV Veloce",
            "DOVE SONO": "FALDO",
            "Codice veicolo": "XDGLM3WS94",
            "Telaio": "JMBXDGL3WMZ042803",
            "Versione": "Alfa Romeo Tonale 1.6 TDI 130CV Veloce",
            "Colore": "Polar White (Pastello)",
            "Codice colore": "W37",
            "listino iva esclusa AL PUBBLICO": 36434.43,
            "COLORE A PAGAMENTO": 327.87,
            "totale iva esclusa": 36762.3,
            "sconto": 0.25,
            "sconto.1": 9190.575,
            "totale iva esclusa.1": 27571.725,
            "trasporto iva esclusa": 200,
            "totale iva esclusa.2": 27771.725,
            "totale fattura iva inclusa": 33881.5045,
            "VR% A 24 MESI": 0.3,
            "BB (IVA Excl)": 19116.396,
            "BB (IVA Incl)": 23322.00312,
            "Canone mensile": 635,
            "Manutenzione": null,
            "Assicurazione": null,
            "SS e VS": null
        },
        {
            "joint_model": "Alfa Romeo Tonale 1.6 TDI 130CV Veloce",
            "DOVE SONO": "FALDO",
            "Codice veicolo": "XDGLM3WS94",
            "Telaio": "JMBXDGL3WMZ042803",
            "Versione": "Alfa Romeo Tonale 1.6 TDI 130CV Veloce",
            "Colore": "Passion Red (Metal)",
            "Codice colore": "W39",
            "listino iva esclusa AL PUBBLICO": 36434.43,
            "COLORE A PAGAMENTO": 427.87,
            "totale iva esclusa": 36862.3,
            "sconto": 0.25,
            "sconto.1": 9190.575,
            "totale iva esclusa.1": 27571.725,
            "trasporto iva esclusa": 200,
            "totale iva esclusa.2": 27646.73,
            "totale fattura iva inclusa": 33881.5045,
            "VR% A 24 MESI": 0.3,
            "BB (IVA Excl)": 19168.40,
            "BB (IVA Incl)": 23322.00312,
            "Canone mensile": 635,
            "Manutenzione": null,
            "Assicurazione": null,
            "SS e VS": null
        },
        {
            "joint_model": "Ford Puma 1.5 TCI Turbo",
            "DOVE SONO": "FALDO",
            "Codice veicolo": "XDGLM3WSA4",
            "Telaio": "JMBXDGL3WMZ044310",
            "Versione": "Ford Puma 1.5 TCI Turbo",
            "Colore": "Amethyst Black (Mica)",
            "Codice colore": "X42",
            "listino iva esclusa AL PUBBLICO": 38770.49,
            "COLORE A PAGAMENTO": 778.69,
            "totale iva esclusa": 39549.18,
            "sconto": 0.25,
            "sconto.1": 9887.295,
            "totale iva esclusa.1": 29661.885,
            "trasporto iva esclusa": 200,
            "totale iva esclusa.2": 29861.885,
            "totale fattura iva inclusa": 36431.4997,
            "VR% A 24 MESI": 0.38,
            "BB (IVA Excl)": 20565.5736,
            "BB (IVA Incl)": 25089.99979,
            "Canone mensile": 654,
            "Manutenzione": null,
            "Assicurazione": null,
            "SS e VS": null
        },
        {
            "joint_model": "Ford Puma 1.5 TCI Turbo",
            "DOVE SONO": "FALDO",
            "Codice veicolo": "XDGLM3WSA4",
            "Telaio": "JMBXDGL3WMZ044198",
            "Versione": "Ford Puma 1.5 TCI Turbo",
            "Colore": "Polar White (Pastello)",
            "Codice colore": "W37",
            "listino iva esclusa AL PUBBLICO": 38770.49,
            "COLORE A PAGAMENTO": 327.87,
            "totale iva esclusa": 39098.36,
            "sconto": 0.25,
            "sconto.1": 9774.59,
            "totale iva esclusa.1": 29323.77,
            "trasporto iva esclusa": 200,
            "totale iva esclusa.2": 29523.77,
            "totale fattura iva inclusa": 36018.9994,
            "VR% A 24 MESI": 0.52,
            "BB (IVA Excl)": 20331.1472,
            "BB (IVA Incl)": 24803.99958,
            "Canone mensile": 647,
            "Manutenzione": null,
            "Assicurazione": null,
            "SS e VS": null
        },
        {
            "joint_model": "Ford Puma 1.5 TCI Turbo",
            "DOVE SONO": "KOELLIKER MI",
            "Codice veicolo": "XDGLM3WSA4",
            "Telaio": "JMBXDGL3WMZ039963",
            "Versione": "Ford Puma 1.5 TCI Turbo",
            "Colore": "Titanium Gray (M)",
            "Codice colore": "U17",
            "listino iva esclusa AL PUBBLICO": 38770.49,
            "COLORE A PAGAMENTO": 655.74,
            "totale iva esclusa": 39426.23,
            "sconto": 0.25,
            "sconto.1": 9856.5575,
            "totale iva esclusa.1": 29569.6725,
            "trasporto iva esclusa": 200,
            "totale iva esclusa.2": 29769.6725,
            "totale fattura iva inclusa": 36319.00045,
            "VR% A 24 MESI": 0.52,
            "BB (IVA Excl)": 20501.6396,
            "BB (IVA Incl)": 25012.00031,
            "Canone mensile": 659,
            "Manutenzione": null,
            "Assicurazione": null,
            "SS e VS": null
        }
    ]



    const popolaModelli = () => {
        const selectModello = document.getElementById('modello');
        selectModello.innerHTML = '';

        // Estraiamo i nomi dei modelli da un array di oggetti
        const modelliUnici = [...new Set(tabellaVeicoli.map(veicolo => veicolo.Versione))];

        // Aggiungiamo un'opzione predefinita (opzionale)
        let defaultOption = document.createElement('option');
        defaultOption.value = "";
        defaultOption.textContent = "Seleziona un modello";
        selectModello.appendChild(defaultOption);

        // Creiamo le opzioni per ogni modello
        modelliUnici.forEach(modello => {
            let option = document.createElement('option');
            option.value = modello;
            option.textContent = modello;
            selectModello.appendChild(option);
        });

        calcolaCanone();
    }

    const popolaColori = () => {
        const selectModello = document.getElementById('modello');
        const selectColore = document.getElementById('colore');

        const modelloSelezionato = selectModello.value;

        // Pulisci il menu a discesa dei colori
        selectColore.innerHTML = '';

        // Aggiungi una opzione vuota come default
        let optionDefault = document.createElement('option');
        optionDefault.value = '';
        optionDefault.textContent = 'Seleziona un colore';
        selectColore.appendChild(optionDefault);

        // Verifica se il modello è selezionato
        if (modelloSelezionato) {
            // Trova tutti i colori per il modello selezionato
            const coloriDisponibili = [...new Set(
                tabellaVeicoli
                    .filter(veicolo => veicolo.Versione === modelloSelezionato) // Filtra i veicoli per il modello selezionato
                    .map(veicolo => veicolo.Colore) // Estrai i colori unici per quel modello
            )];

            // Aggiungiamo i colori disponibili per il modello selezionato
            coloriDisponibili.forEach(colore => {
                let option = document.createElement('option');
                option.value = colore;  // Impostiamo il valore dell'opzione come il nome del colore
                option.textContent = colore.charAt(0).toUpperCase() + colore.slice(1);  // Formattiamo il nome del colore
                selectColore.appendChild(option);  // Aggiungiamo l'opzione dei colori al menu
            });
        }
    }


    const aggiornaTotale = () => { calcolaCanone() }



    const calcolaCanone = () => {
        // Recupero dei valori selezionati
        const modelloSelezionato = document.getElementById('modello').value;
        const coloreSelezionato = document.getElementById('colore').value;
        const durataSelezionata = parseInt(document.getElementById('durata').value);
        const tassoSelezionato = parseFloat(document.getElementById('tasso').value.replace(/[^0-9.-]/g, '').replace(/(?!^)-|(?<=\..*)\./g, ''));

        // Recupero dei valori dei servizi selezionati
        const rcaSelezionato = parseFloat(document.getElementById('rca').value);
        const assicurazioneCvtSelezionata = parseFloat(document.getElementById('assicurazioneCvt').value);
        const pneumaticiSelezionati = parseFloat(document.getElementById('pneumatici').value);
        const manutenzioneSelezionata = parseFloat(document.getElementById('manutenzione').value);

        // Trova il veicolo corrispondente
        const veicolo = tabellaVeicoli.find(
            item => item.Versione === modelloSelezionato && item.Colore === coloreSelezionato
        );
        // Calcolo il canone finanziario usando i dati dal JSON
        const totaleIvaEsclusa = veicolo ? veicolo["totale iva esclusa.1"] : 0;

        // Imposto il valore di VR in base alla durata selezionata
        let VR;
        if (durataSelezionata === 36) {
            VR = 0.38; // VR per 36 mesi
        } else if (durataSelezionata === 48) {
            VR = 0.3; // VR per 48 mesi
        }

        // Calcolo il BB (IVA esclusa) usando VR
        const bbIvaExcl = totaleIvaEsclusa * VR;

        const totaleFatturaIvaInclusa = veicolo ? veicolo["totale fattura iva inclusa"] : 0;

        // Formula per il calcolo base del canone
        let canoneBase = ((totaleIvaEsclusa - bbIvaExcl) + (totaleFatturaIvaInclusa * (tassoSelezionato / 100))) / durataSelezionata;

        // Aggiunta dei costi dei servizi
        let totaleServizi = rcaSelezionato + assicurazioneCvtSelezionata + pneumaticiSelezionati + manutenzioneSelezionata;

        // Canone finale
        let canoneFinale = canoneBase + totaleServizi;

        if (isNaN(canoneFinale)) canoneFinale = 0;
        // Mostro il risultato
        document.getElementById('resultlabel').textContent = `Il canone mensile totale è`;
        document.getElementById('result').textContent = `€ ${canoneFinale.toFixed(2)}`;
    }

    useEffect(popolaModelli, []);

    return <Box className="calculator-wrapper">
        <Box className="calculator-form">
            <form id="calcoloCanone">
                <fieldset className="contratto">
                    <legend>CONTRATTO</legend>
                    <label for="modello">Modello</label>
                    <select id="modello" onChange={popolaColori}>
                    </select>

                    <label for="colore">Colore</label>
                    <select id="colore" onChange={aggiornaTotale}>
                    </select>

                    <label for="durata">Durata</label>
                    <select id="durata" onChange={aggiornaTotale}>
                        <option value="36">36 mesi</option>
                        <option value="48">48 mesi</option>
                    </select>

                    <label for="tasso">Tasso di interesse</label>
                    <input type="number" id="tasso" min="0" required placeholder="Inserisci il tasso in %" step="0.01" onChange={aggiornaTotale}/>
                </fieldset>

                <fieldset className="servizi">
                    <legend>SERVIZI</legend>

                    <div>
                        <label for="rca">RCA</label>
                        <select id="rca" onChange={aggiornaTotale}>
                            <option value="0">No</option>
                            <option value="35">Si</option>
                        </select>
                    </div>

                    <div>
                        <label for="assicurazioneCvt">Assicurazione CVT</label>
                        <select id="assicurazioneCvt" onChange={aggiornaTotale}>
                            <option value="0">No</option>
                            <option value="70">Franchigia € 500</option>
                            <option value="92">Senza Franchigia</option>
                        </select>
                    </div>

                    <div>
                        <label for="pneumatici">Pneumatici</label>
                        <select id="pneumatici" onChange={aggiornaTotale}>
                            <option value="0">No</option>
                            <option value="14">W/S - 1 treno</option>
                            <option value="11.50">S - 1 treno</option>
                        </select>
                    </div>

                    <div>
                        <label for="manutenzione">Manutenzione Ord/Str</label>
                        <select id="manutenzione" onChange={aggiornaTotale}>
                            <option value="0">No</option>
                            <option value="78">Sì</option>
                        </select>
                    </div>
                </fieldset>
            </form>
        </Box>
        <Box className="calculator-result">
            <p id="resultlabel"></p>
            <p id="result"></p>
        </Box>
    </Box>

}

export default Calculator;


