import React, { useState, useEffect, useContext } from 'react';
import { useForm } from "react-hook-form"

import { Box, Select, Option } from '@mui/joy';
import { default as ReactApexChart } from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { useFormatter } from "../../utils";
import { Tag } from "../Snippet";
import { Icon } from "../../assets";
import { useDataProvider } from '../../../mtska-frontend-data-provider';
import { useDrawer } from "../Drawer";
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const VehicleKilometer = ({ item, contractStartDate, contractEndDate, contractMonths, contractKilometers, contractCostKMExcess, contractKMReimbursement, contractCostKMCurrency, tracingSerie }) => {
    const { t, i18n } = useTranslation();
    const { formatCurrency, formatDistance } = useFormatter();
    const { activate } = useDrawer();
    const { userCapabilities } = useContext(AppContext);

    contractMonths = parseInt(contractMonths);
    contractKilometers = parseInt(contractKilometers);
    contractCostKMExcess = parseFloat(contractCostKMExcess);
    contractKMReimbursement = parseFloat(contractKMReimbursement);

    const calculateEndDate = (contractStartDate, contractMonths) => {
        const cedcalc = (new Date(contractStartDate));
        cedcalc.setMonth(cedcalc.getMonth() + parseInt(contractMonths));
        return cedcalc;
    }

    const { loadOne } = useDataProvider('cars/car');
    const handleRefineContract = () => {
        activate('contracts/detail', {
            id: item?.contract?.id,
            defaultValue: item?.contract,
            filters: item?.contract,
        }, () => loadOne(item?.id));
    };

    // oggi
    const [td, setTd] = useState(new Date());

    // data di inizio contratto
    const [csd, setCsd] = useState(new Date(contractStartDate));

    // data di fine contratto
    const [ced, setCed] = useState(new Date(contractEndDate));

    // data calcolata di fine contratto
    const [cedcalc, setCedcalc] = useState(calculateEndDate(contractStartDate, contractMonths));

    // data ultimo rilevamento kilometrico (last trace date)
    const [ltd, setLtd] = useState((Array.isArray(tracingSerie) && tracingSerie.length > 0) ? new Date(tracingSerie[0].traced_at) : new Date(csd));

    // km al mese da contratto
    const [km_month_contract, setKmMonthContract] = useState(contractKilometers / contractMonths);

    // mesi da inizio contratto a ultimo rilevamento
    const [months_from_start_to_lasttrace, setMonthsFromStartToLasttrace] = useState(ltd?.getMonth() - csd.getMonth() + (12 * (ltd?.getFullYear() - csd.getFullYear())));

    // mesi da ultimo rilevamento a fine contratto
    const [months_from_lasttrace_to_end, setMonthsFromLasttraceToEnd] = useState(cedcalc.getMonth() - ltd?.getMonth() + (12 * (cedcalc.getFullYear() - ltd?.getFullYear())) - 1);

    //console.log({'contractMonths':contractMonths, 'months_from_start_to_lasttrace': months_from_start_to_lasttrace, 'months_from_lasttrace_to_end': months_from_lasttrace_to_end})
    // km totali all'ultimo rilevamento
    const [km_total_actual, setKmTotalActual] = useState((Array.isArray(tracingSerie) && tracingSerie.length > 0) ? parseInt(tracingSerie[0].value) : 0);

    // km totali previsti
    const [km_total_forecast, setKmTotalForecast] = useState(null);

    // km al mese previsti
    const [km_month_forecast, setKmMonthForecast] = useState(null);

    // costo km eccesso
    const [km_cost_forecast, setKmCostForecast] = useState(null);

    // km al mese effettivi
    const [km_month_actual, setKmMonthActual] = useState((months_from_start_to_lasttrace > 0) ? km_total_actual / months_from_start_to_lasttrace : 0);

    // km al mese correttivi
    const [km_month_corrective, setKmMonthCorrective] = useState((contractKilometers - km_total_actual) / (months_from_lasttrace_to_end + 1));

    // strategia di riempimento della serie di rilevamenti futuri
    const [forecastStrategy, setForecastStrategy] = useState("contract");

    // serie da disegnare
    const [series, setSeries] = useState(null);

    // drawable (dati pronti)
    const [drawable, setDrawable] = useState(false);

    useEffect(() => {
        // preparo la serie da disegnare
        setSeries([contractSerieCalculate(), forecastSerieCalculate()]);
        setDrawable(true);
    }, [forecastStrategy]);

    const contractSerieCalculate = () => {
        // creo la serie basata sul contratto
        const contract_serie = [];

        for (let i = 0; i < contractMonths; i++) {
            let d = (new Date(csd));
            d.setMonth(csd.getMonth() + i);

            //console.log("contract iteration "+i+" "+contractMonths, { x: d, y: km_month_contract * i })
            contract_serie.push({ x: d.getTime(), y: parseInt(km_month_contract * i), date: d, iterator: i });
        }
        // inserisco l'ultimo record manualmente per farlo corrispondere esattamente alla fine del contratto
        contract_serie.push({ x: cedcalc.getTime(), y: parseInt(km_month_contract * contractMonths), date: cedcalc, iterator: contractMonths });

        return { name: t('Contract KM'), data: contract_serie };
    }

    const forecastSerieCalculate = () => {
        let km_month_forecast;
        let actual_serie = [];

        let start_d = (new Date(ltd));
        start_d.setDate(csd.getDate());
        if (start_d < ltd) {
            start_d.setMonth(start_d.getMonth() + 1);
        }
        let start_km = km_total_actual;

        if (forecastStrategy == "contract") {
            km_month_forecast = km_month_contract;
        } else if (forecastStrategy == "actual") {
            km_month_forecast = km_month_actual;
        } else if (forecastStrategy == "corrective") {
            km_month_forecast = km_month_corrective;
        }

        // converto il tracciato per usarlo in Apex
        for (let i = 0; i < tracingSerie?.length; i++) {
            let d = (new Date(tracingSerie[i].traced_at));
            actual_serie.unshift({ x: d.getTime(), y: parseInt(tracingSerie[i].value), date_past: d });
        }

        // inserisco la data di inizio contratto a zero
        actual_serie.unshift({ x: csd.getTime(), y: 0, date_past: csd });

        let stepcorrector = 0;
        for (let i = 0; i <= months_from_lasttrace_to_end; i++) {
            let d = (new Date(start_d));
            d.setMonth(start_d.getMonth() + i);

            // se il primo dato delle proiezioni future cade nello stesso mese dell'ultimo tracciamento, salto al successivo
            if (csd.getTime() != ltd.getTime() && i == 0 && ltd.getMonth() == d.getMonth() && ltd.getYear() == d.getYear()) {
                continue;
            } else if (csd.getTime() != ltd.getTime() && i == 0) {
                // devo impostare il correttore in maniera che la serie cresca di uno step in più (altrimenti fa la gobba)
                stepcorrector = 1;
            }
            actual_serie.push({ x: d.getTime(), y: parseInt(start_km + (km_month_forecast * (i + stepcorrector))), date_future: d });
        }

        // inserisco la data di fine contratto a mano
        const forecast_km = parseInt(start_km + (km_month_forecast * (months_from_lasttrace_to_end + 1)));
        actual_serie.push({ x: cedcalc.getTime(), y: forecast_km, date_future: cedcalc });

        setKmTotalForecast(forecast_km);
        setKmMonthForecast(km_month_forecast);

        if (forecast_km < contractKilometers) {
            setKmCostForecast(isNaN(contractKMReimbursement) ? 0 : (forecast_km - contractKilometers) * contractKMReimbursement);
        } else {
            setKmCostForecast(isNaN(contractCostKMExcess) ? 0 : (forecast_km - contractKilometers) * contractCostKMExcess);
        }

        return { name: t('Actual KM'), data: actual_serie };
    }

    const [options, setOptions] = useState({
        chart: {
            width: '100%',
            height: '240px',
            toolbar: {
                autoSelected: "pan",
                //show: false
            },
            // zoom: {
            //     enabled: false,
            // },
            fontFamily: 'Inter, Helvetica, Arial, sans-serif',
            defaultLocale: i18n.resolvedLanguage,
            locales: [{
                name: i18n.resolvedLanguage,
                options: {
                    months: [t('January'), t('February'), t('March'), t('April'), t('May'), t('June'), t('July'), t('August'), t('September'), t('October'), t('November'), t('December')],
                    shortMonths: [t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May_'), t('Jun'), t('Jul'), t('Aug'), t('Sep'), t('Oct'), t('Nov'), t('Dec')],
                }
            }]
        },
        forecastDataPoints: {
            //count: months_from_lasttrace_to_end
        },
        xaxis: {
            type: 'datetime',
            labels: {
                style: {
                    colors: ['#9FA6AD'],
                    fontSize: '11px',
                    fontWeight: 400
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: ['#9FA6AD'],
                    fontSize: '11px',
                    fontWeight: 400
                }
            }
        },
        colors: ["#51BC51", "#0BA5EC"],

        markers: {
            size: [1, 2],
            strokeColors: ["#51BC51", "#0BA5EC"],
            strokeWidth: [1, 1],
            colors: ["#FFF", "#FFF"],
            // discrete: [
            //         {
            //             seriesIndex: 1,
            //             dataPointIndex: tracingSerie?.length - 1,
            //             fillColor: '#FFF',
            //             strokeColor: '#FF0000',
            //             size: 6,
            //             shape: "circle"
            //         }
            //     ]
        },
        stroke: {
            width: [1, 1],
            curve: 'straight'
        },
        legend: {
            show: false,
        },
        fill: {
            type: "gradient",
            gradient: {
                opacityFrom: 0.4,
                opacityTo: 0,
                stops: [0, 100],
                type: "vertical",
                shadeIntensity: 0.5,
            }
        },
        dataLabels: {
            enabled: false,
        },
        annotations: {
            xaxis: [
                {
                    x: new Date().getTime(),
                    borderColor: '#9FA6AD',
                    strokeDashArray: 0
                },
                {
                    x: new Date().getTime(),
                    x2: new Date(cedcalc).getTime(),
                    fillColor: '#F0F4F8',
                }
            ]
        }
    });

    if (drawable) {
        // SCHIFEZZA NECESSARIA PERCHÈ SE LE SERIE DIFFERISCONO DI UN ELEMENTO IL GRAFICO SI DISEGNA MALE
        if (Math.abs(series[0].data.length - series[1].data.length) == 1) series[1].data.splice(1, 1);
    }

    const { control } = useForm({
        values: { 'input-select-strategy': forecastStrategy },
    });

    return (
        drawable && (
            <Box className="mtksa-chart mtksa-chart-vehicle-kilometer">
                <Box className="chartwrapper">
                    <ReactApexChart
                        type="area"
                        options={options}
                        series={series}
                        height="100%"
                    />
                </Box>
                <Box className="forecastwrapper">
                    <Select
                        id="input-select-strategy"
                        name="input-select-strategy"
                        value={forecastStrategy}
                        onChange={(e, v) => { setForecastStrategy(v) }}
                        variant="outlined"
                        autoComplete="off"
                        className="form-element-field form-element-field-select"
                    >
                        {
                            [
                                {
                                    label: t("Contract pace"),
                                    value: "contract",
                                },
                                {
                                    label: t("Actual pace"),
                                    value: "actual",
                                },
                                {
                                    label: t("Corrective pace"),
                                    value: "corrective",
                                }
                            ].map((option, k) => (
                                <Option key={k} value={option.value}>{option.label}</Option>
                            ))
                        }
                    </Select>

                    <Box className="result">
                        <Box className="info">
                            <span className='key'>{t('Actual KM')}</span>
                            <span className='value'>{formatDistance(km_total_actual)}</span>
                        </Box>
                        <Box className="info">
                            <span className='key'>{t('Actual monthly KM')}</span>
                            <span className='value'>{formatDistance(km_month_actual)}</span>
                        </Box>
                        <Box className="info">
                            <span className='key'>{t('Contract monthly KM')}</span>
                            <span className='value'>
                                {!isNaN(km_month_contract) ? formatDistance(km_month_contract) : <Tag color={"danger"} title={t("Missing Contract KM")} onClick={handleRefineContract}><Icon icon="faWarning" />{t('Missing')}</Tag>}
                            </span>
                        </Box>
                        <Box className="info forecast">
                            <span className='key'>{t('Forecast total KM')}</span>
                            <span className='value'>{formatDistance(km_total_forecast)}</span>
                        </Box>
                        <Box className={"info forecast" + (km_month_forecast < km_month_contract ? ' warning' : '')}>
                            <span className='key'>{t('Forecast monthly KM')}</span>
                            <span className='value'>{formatDistance(km_month_forecast)}</span>
                        </Box>

                        {
                            !userCapabilities['auth/role.is_driver'] && (
                                <Box className={"info forecast" + (km_cost_forecast > 0 ? ' warning' : '')}>
                                    <span className='key'>{(km_cost_forecast > 0) ? t('Forecast cost KM') : t('Forecast refund')}</span>
                                    <span className='value'>
                                        {
                                            (km_cost_forecast > 0 && isNaN(contractCostKMExcess)) && (<Tag color={"danger"} title={t("Missing contractCostKMExcess")} onClick={handleRefineContract}><Icon icon="faWarning" />{t('Missing')}</Tag>)
                                        }
                                        {
                                            (km_cost_forecast <= 0 && isNaN(contractKMReimbursement)) && (<Tag color={"danger"} title={t("Missing contractKMReimbursement")} onClick={handleRefineContract}><Icon icon="faWarning" />{t('Missing')}</Tag>)
                                        }
                                        {
                                            ((km_cost_forecast > 0 && !isNaN(contractCostKMExcess)) || (km_cost_forecast <= 0 && !isNaN(contractKMReimbursement))) && (formatCurrency(km_cost_forecast))
                                        }
                                    </span>
                                </Box>
                            )
                        }
                    </Box>
                </Box>
            </Box>
        )
    );
}

export default VehicleKilometer;