import React, { useState } from 'react'
import { Box, IconButton, Popover } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

export const HamburgerMenu = ({ renderContent = () => { } }) => {

    const [anchorEl, setAnchorEl] = useState(null)

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'user-menu-popover' : undefined

    return (
        <>
            <IconButton onClick={handleOpen} sx={{ color: 'inherit' }}>
                <MenuIcon />
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                id={id}
                onClose={handleClose}
                open={open}
            >
                <Box p={2} sx={{ minWidth: 200, width: "auto", height: "auto" }}>
                    {renderContent({ closePopover: handleClose })}
                </Box>
            </Popover>
        </>
    )
}
