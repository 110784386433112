import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer, useRelationshipInjector, useDetailConfigOverrider } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'opportunities/proposal';
const model = 'opportunities/proposal';
const modelExposedName = 'Proposals';
const wizardAction = 'create';

const Create = (params) => {
    console.info('PARAMS IN CREATE', params);

    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);
    // const { injectToDetail } = useRelationshipInjector();
    // const { overrideDetailFields } = useDetailConfigOverrider();
    // DATA PROVIDER
    const { doAction, item, loadOne, loadAll, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);

    const modelName = existingModules.modelName
    //const detailConfig = (params.detailConfigOverride) ? overrideDetailFields(existingModules[dataProviderName].detailConfig, params.detailConfigOverride) : existingModules[dataProviderName].detailConfig;
    //const detailConfig = existingModules[dataProviderName].detailConfig;
    //console.info(JSON.stringify(detailConfig));
    const detailConfig = {
        "tabs": [
            {
                "name": "proposal",
                "fieldsets": [
                    {
                        "name": "proposal",
                        "fields": {
                            "propertyID": {
                                "type": "text",
                                "className": "col-3",
                                "icon": "faInputText"
                            },
                            "product": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "buy",
                                        "value": "buy"
                                    },
                                    {
                                        "label": "sell",
                                        "value": "sell"
                                    },
                                    {
                                        "label": "lease_out",
                                        "value": "lease_out"
                                    },
                                    {
                                        "label": "rent",
                                        "value": "rent"
                                    },
                                    {
                                        "label": "referral_out",
                                        "value": "referral_out"
                                    },
                                    {
                                        "label": "referral_in",
                                        "value": "referral_in"
                                    },
                                    {
                                        "label": "penalty_fee",
                                        "value": "penalty_fee"
                                    }
                                ],
                                "className": "col-3",
                                "icon": "faCartShopping"
                            },
                            "status": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "accepted",
                                        "value": "accepted"
                                    },
                                    {
                                        "label": "not_accepted",
                                        "value": "not_accepted"
                                    },
                                    {
                                        "label": "in_progress",
                                        "value": "in_progress"
                                    }
                                ],
                                "className": "col-3",
                                "icon": "faCartShopping"
                            },
                            "registered_at": {
                                "type": "date",
                                "className": "col-3",
                                "icon": "faCalendarStar"
                            },
                            "requestValue": {
                                "type": "number",
                                "className": "col-3",
                                "icon": "faArrowRightToArc"
                            },
                            "offerValue": {
                                "type": "number",
                                "className": "col-3",
                                "icon": "faArrowRightFromArc"
                            },
                            "deltaValue": {
                                "type": "number",
                                "className": "col-3",
                                "icon": "faMoneyBill"
                            },
                            "deltaPercentage": {
                                "type": "number",
                                "className": "col-3",
                                "icon": "faPercent"
                            },
                            "finalValue": {
                                "type": "number",
                                "className": "col-12",
                                "icon": "faMoneyBill"
                            },
                            "areasource": {
                                "type": "relationshipSingle",
                                "relation": {
                                    "drawerRoute": "areaSources",
                                    "limit": 1000
                                },
                                "view": "areaSource"
                            },
                            "area": {
                                "type": "relationshipSingle",
                                "relation": {
                                    "drawerRoute": "areas",
                                    "limit": 1000
                                },
                                "view": "area"
                            },
                            "shop": {
                                "type": "relationshipSingle",
                                "relation": {
                                    "drawerRoute": "shops",
                                    "limit": 1000
                                },
                                "view": "shop"
                            },
                            "assistant": {
                                "type": "relationshipSingle",
                                "relation": {
                                    "drawerRoute": "employments",
                                    "limit": 1000
                                },
                                "view": "employment"
                            }
                        }
                    },
                    {
                        "name": "buyer",
                        "fields": {
                            "buyerName": {
                                "type": "text",
                                "className": "col-3"
                            },
                            "buyerNationality": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "afghanistan",
                                        "value": "afghanistan"
                                    },
                                    {
                                        "label": "albania",
                                        "value": "albania"
                                    },
                                    {
                                        "label": "algeria",
                                        "value": "algeria"
                                    },
                                    {
                                        "label": "andorra",
                                        "value": "andorra"
                                    },
                                    {
                                        "label": "angola",
                                        "value": "angola"
                                    },
                                    {
                                        "label": "antiguaAndBarbuda",
                                        "value": "antiguaAndBarbuda"
                                    },
                                    {
                                        "label": "argentina",
                                        "value": "argentina"
                                    },
                                    {
                                        "label": "armenia",
                                        "value": "armenia"
                                    },
                                    {
                                        "label": "australia",
                                        "value": "australia"
                                    },
                                    {
                                        "label": "austria",
                                        "value": "austria"
                                    },
                                    {
                                        "label": "azerbaijan",
                                        "value": "azerbaijan"
                                    },
                                    {
                                        "label": "bahamas",
                                        "value": "bahamas"
                                    },
                                    {
                                        "label": "bahrain",
                                        "value": "bahrain"
                                    },
                                    {
                                        "label": "bangladesh",
                                        "value": "bangladesh"
                                    },
                                    {
                                        "label": "barbados",
                                        "value": "barbados"
                                    },
                                    {
                                        "label": "belarus",
                                        "value": "belarus"
                                    },
                                    {
                                        "label": "belgium",
                                        "value": "belgium"
                                    },
                                    {
                                        "label": "belize",
                                        "value": "belize"
                                    },
                                    {
                                        "label": "benin",
                                        "value": "benin"
                                    },
                                    {
                                        "label": "bhutan",
                                        "value": "bhutan"
                                    },
                                    {
                                        "label": "bolivia",
                                        "value": "bolivia"
                                    },
                                    {
                                        "label": "bosniaAndHerzegovina",
                                        "value": "bosniaAndHerzegovina"
                                    },
                                    {
                                        "label": "botswana",
                                        "value": "botswana"
                                    },
                                    {
                                        "label": "brazil",
                                        "value": "brazil"
                                    },
                                    {
                                        "label": "brunei",
                                        "value": "brunei"
                                    },
                                    {
                                        "label": "bulgaria",
                                        "value": "bulgaria"
                                    },
                                    {
                                        "label": "burkinaFaso",
                                        "value": "burkinaFaso"
                                    },
                                    {
                                        "label": "burundi",
                                        "value": "burundi"
                                    },
                                    {
                                        "label": "caboVerde",
                                        "value": "caboVerde"
                                    },
                                    {
                                        "label": "cambodia",
                                        "value": "cambodia"
                                    },
                                    {
                                        "label": "cameroon",
                                        "value": "cameroon"
                                    },
                                    {
                                        "label": "canada",
                                        "value": "canada"
                                    },
                                    {
                                        "label": "centralAfricanRepublic",
                                        "value": "centralAfricanRepublic"
                                    },
                                    {
                                        "label": "chad",
                                        "value": "chad"
                                    },
                                    {
                                        "label": "chile",
                                        "value": "chile"
                                    },
                                    {
                                        "label": "china",
                                        "value": "china"
                                    },
                                    {
                                        "label": "colombia",
                                        "value": "colombia"
                                    },
                                    {
                                        "label": "comoros",
                                        "value": "comoros"
                                    },
                                    {
                                        "label": "congo",
                                        "value": "congo"
                                    },
                                    {
                                        "label": "democraticRepublicOfTheCongo",
                                        "value": "democraticRepublicOfTheCongo"
                                    },
                                    {
                                        "label": "costaRica",
                                        "value": "costaRica"
                                    },
                                    {
                                        "label": "coteDIvoire",
                                        "value": "coteDIvoire"
                                    },
                                    {
                                        "label": "croatia",
                                        "value": "croatia"
                                    },
                                    {
                                        "label": "cuba",
                                        "value": "cuba"
                                    },
                                    {
                                        "label": "cyprus",
                                        "value": "cyprus"
                                    },
                                    {
                                        "label": "czechRepublic",
                                        "value": "czechRepublic"
                                    },
                                    {
                                        "label": "denmark",
                                        "value": "denmark"
                                    },
                                    {
                                        "label": "djibouti",
                                        "value": "djibouti"
                                    },
                                    {
                                        "label": "dominica",
                                        "value": "dominica"
                                    },
                                    {
                                        "label": "dominicanRepublic",
                                        "value": "dominicanRepublic"
                                    },
                                    {
                                        "label": "ecuador",
                                        "value": "ecuador"
                                    },
                                    {
                                        "label": "egypt",
                                        "value": "egypt"
                                    },
                                    {
                                        "label": "elSalvador",
                                        "value": "elSalvador"
                                    },
                                    {
                                        "label": "equatorialGuinea",
                                        "value": "equatorialGuinea"
                                    },
                                    {
                                        "label": "eritrea",
                                        "value": "eritrea"
                                    },
                                    {
                                        "label": "estonia",
                                        "value": "estonia"
                                    },
                                    {
                                        "label": "eswatini",
                                        "value": "eswatini"
                                    },
                                    {
                                        "label": "ethiopia",
                                        "value": "ethiopia"
                                    },
                                    {
                                        "label": "fiji",
                                        "value": "fiji"
                                    },
                                    {
                                        "label": "finland",
                                        "value": "finland"
                                    },
                                    {
                                        "label": "france",
                                        "value": "france"
                                    },
                                    {
                                        "label": "gabon",
                                        "value": "gabon"
                                    },
                                    {
                                        "label": "gambia",
                                        "value": "gambia"
                                    },
                                    {
                                        "label": "georgia",
                                        "value": "georgia"
                                    },
                                    {
                                        "label": "germany",
                                        "value": "germany"
                                    },
                                    {
                                        "label": "ghana",
                                        "value": "ghana"
                                    },
                                    {
                                        "label": "greece",
                                        "value": "greece"
                                    },
                                    {
                                        "label": "grenada",
                                        "value": "grenada"
                                    },
                                    {
                                        "label": "guatemala",
                                        "value": "guatemala"
                                    },
                                    {
                                        "label": "guinea",
                                        "value": "guinea"
                                    },
                                    {
                                        "label": "guineaBissau",
                                        "value": "guineaBissau"
                                    },
                                    {
                                        "label": "guyana",
                                        "value": "guyana"
                                    },
                                    {
                                        "label": "haiti",
                                        "value": "haiti"
                                    },
                                    {
                                        "label": "honduras",
                                        "value": "honduras"
                                    },
                                    {
                                        "label": "hungary",
                                        "value": "hungary"
                                    },
                                    {
                                        "label": "iceland",
                                        "value": "iceland"
                                    },
                                    {
                                        "label": "india",
                                        "value": "india"
                                    },
                                    {
                                        "label": "indonesia",
                                        "value": "indonesia"
                                    },
                                    {
                                        "label": "iran",
                                        "value": "iran"
                                    },
                                    {
                                        "label": "iraq",
                                        "value": "iraq"
                                    },
                                    {
                                        "label": "ireland",
                                        "value": "ireland"
                                    },
                                    {
                                        "label": "israel",
                                        "value": "israel"
                                    },
                                    {
                                        "label": "italy",
                                        "value": "italy"
                                    },
                                    {
                                        "label": "jamaica",
                                        "value": "jamaica"
                                    },
                                    {
                                        "label": "japan",
                                        "value": "japan"
                                    },
                                    {
                                        "label": "jordan",
                                        "value": "jordan"
                                    },
                                    {
                                        "label": "kazakhstan",
                                        "value": "kazakhstan"
                                    },
                                    {
                                        "label": "kenya",
                                        "value": "kenya"
                                    },
                                    {
                                        "label": "kiribati",
                                        "value": "kiribati"
                                    },
                                    {
                                        "label": "koreaNorth",
                                        "value": "koreaNorth"
                                    },
                                    {
                                        "label": "koreaSouth",
                                        "value": "koreaSouth"
                                    },
                                    {
                                        "label": "kuwait",
                                        "value": "kuwait"
                                    },
                                    {
                                        "label": "kyrgyzstan",
                                        "value": "kyrgyzstan"
                                    },
                                    {
                                        "label": "laos",
                                        "value": "laos"
                                    },
                                    {
                                        "label": "latvia",
                                        "value": "latvia"
                                    },
                                    {
                                        "label": "lebanon",
                                        "value": "lebanon"
                                    },
                                    {
                                        "label": "lesotho",
                                        "value": "lesotho"
                                    },
                                    {
                                        "label": "liberia",
                                        "value": "liberia"
                                    },
                                    {
                                        "label": "libya",
                                        "value": "libya"
                                    },
                                    {
                                        "label": "liechtenstein",
                                        "value": "liechtenstein"
                                    },
                                    {
                                        "label": "lithuania",
                                        "value": "lithuania"
                                    },
                                    {
                                        "label": "luxembourg",
                                        "value": "luxembourg"
                                    },
                                    {
                                        "label": "madagascar",
                                        "value": "madagascar"
                                    },
                                    {
                                        "label": "malawi",
                                        "value": "malawi"
                                    },
                                    {
                                        "label": "malaysia",
                                        "value": "malaysia"
                                    },
                                    {
                                        "label": "maldives",
                                        "value": "maldives"
                                    },
                                    {
                                        "label": "mali",
                                        "value": "mali"
                                    },
                                    {
                                        "label": "malta",
                                        "value": "malta"
                                    },
                                    {
                                        "label": "marshallIslands",
                                        "value": "marshallIslands"
                                    },
                                    {
                                        "label": "mauritania",
                                        "value": "mauritania"
                                    },
                                    {
                                        "label": "mauritius",
                                        "value": "mauritius"
                                    },
                                    {
                                        "label": "mexico",
                                        "value": "mexico"
                                    },
                                    {
                                        "label": "micronesia",
                                        "value": "micronesia"
                                    },
                                    {
                                        "label": "moldova",
                                        "value": "moldova"
                                    },
                                    {
                                        "label": "monaco",
                                        "value": "monaco"
                                    },
                                    {
                                        "label": "mongolia",
                                        "value": "mongolia"
                                    },
                                    {
                                        "label": "montenegro",
                                        "value": "montenegro"
                                    },
                                    {
                                        "label": "morocco",
                                        "value": "morocco"
                                    },
                                    {
                                        "label": "mozambique",
                                        "value": "mozambique"
                                    },
                                    {
                                        "label": "myanmar",
                                        "value": "myanmar"
                                    },
                                    {
                                        "label": "namibia",
                                        "value": "namibia"
                                    },
                                    {
                                        "label": "nauru",
                                        "value": "nauru"
                                    },
                                    {
                                        "label": "nepal",
                                        "value": "nepal"
                                    },
                                    {
                                        "label": "netherlands",
                                        "value": "netherlands"
                                    },
                                    {
                                        "label": "newZealand",
                                        "value": "newZealand"
                                    },
                                    {
                                        "label": "nicaragua",
                                        "value": "nicaragua"
                                    },
                                    {
                                        "label": "niger",
                                        "value": "niger"
                                    },
                                    {
                                        "label": "nigeria",
                                        "value": "nigeria"
                                    },
                                    {
                                        "label": "northMacedonia",
                                        "value": "northMacedonia"
                                    },
                                    {
                                        "label": "norway",
                                        "value": "norway"
                                    },
                                    {
                                        "label": "oman",
                                        "value": "oman"
                                    },
                                    {
                                        "label": "pakistan",
                                        "value": "pakistan"
                                    },
                                    {
                                        "label": "palau",
                                        "value": "palau"
                                    },
                                    {
                                        "label": "panama",
                                        "value": "panama"
                                    },
                                    {
                                        "label": "papuaNewGuinea",
                                        "value": "papuaNewGuinea"
                                    },
                                    {
                                        "label": "paraguay",
                                        "value": "paraguay"
                                    },
                                    {
                                        "label": "peru",
                                        "value": "peru"
                                    },
                                    {
                                        "label": "philippines",
                                        "value": "philippines"
                                    },
                                    {
                                        "label": "poland",
                                        "value": "poland"
                                    },
                                    {
                                        "label": "portugal",
                                        "value": "portugal"
                                    },
                                    {
                                        "label": "qatar",
                                        "value": "qatar"
                                    },
                                    {
                                        "label": "romania",
                                        "value": "romania"
                                    },
                                    {
                                        "label": "russia",
                                        "value": "russia"
                                    },
                                    {
                                        "label": "rwanda",
                                        "value": "rwanda"
                                    },
                                    {
                                        "label": "saintKittsAndNevis",
                                        "value": "saintKittsAndNevis"
                                    },
                                    {
                                        "label": "saintLucia",
                                        "value": "saintLucia"
                                    },
                                    {
                                        "label": "saintVincentAndTheGrenadines",
                                        "value": "saintVincentAndTheGrenadines"
                                    },
                                    {
                                        "label": "samoa",
                                        "value": "samoa"
                                    },
                                    {
                                        "label": "sanMarino",
                                        "value": "sanMarino"
                                    },
                                    {
                                        "label": "saoTomeAndPrincipe",
                                        "value": "saoTomeAndPrincipe"
                                    },
                                    {
                                        "label": "saudiArabia",
                                        "value": "saudiArabia"
                                    },
                                    {
                                        "label": "senegal",
                                        "value": "senegal"
                                    },
                                    {
                                        "label": "serbia",
                                        "value": "serbia"
                                    },
                                    {
                                        "label": "seychelles",
                                        "value": "seychelles"
                                    },
                                    {
                                        "label": "sierraLeone",
                                        "value": "sierraLeone"
                                    },
                                    {
                                        "label": "singapore",
                                        "value": "singapore"
                                    },
                                    {
                                        "label": "slovakia",
                                        "value": "slovakia"
                                    },
                                    {
                                        "label": "slovenia",
                                        "value": "slovenia"
                                    },
                                    {
                                        "label": "solomonIslands",
                                        "value": "solomonIslands"
                                    },
                                    {
                                        "label": "somalia",
                                        "value": "somalia"
                                    },
                                    {
                                        "label": "southAfrica",
                                        "value": "southAfrica"
                                    },
                                    {
                                        "label": "southSudan",
                                        "value": "southSudan"
                                    },
                                    {
                                        "label": "spain",
                                        "value": "spain"
                                    },
                                    {
                                        "label": "sriLanka",
                                        "value": "sriLanka"
                                    },
                                    {
                                        "label": "sudan",
                                        "value": "sudan"
                                    },
                                    {
                                        "label": "suriname",
                                        "value": "suriname"
                                    },
                                    {
                                        "label": "sweden",
                                        "value": "sweden"
                                    },
                                    {
                                        "label": "switzerland",
                                        "value": "switzerland"
                                    },
                                    {
                                        "label": "syria",
                                        "value": "syria"
                                    },
                                    {
                                        "label": "taiwan",
                                        "value": "taiwan"
                                    },
                                    {
                                        "label": "tajikistan",
                                        "value": "tajikistan"
                                    },
                                    {
                                        "label": "tanzania",
                                        "value": "tanzania"
                                    },
                                    {
                                        "label": "thailand",
                                        "value": "thailand"
                                    },
                                    {
                                        "label": "timorLeste",
                                        "value": "timorLeste"
                                    },
                                    {
                                        "label": "togo",
                                        "value": "togo"
                                    },
                                    {
                                        "label": "tonga",
                                        "value": "tonga"
                                    },
                                    {
                                        "label": "trinidadAndTobago",
                                        "value": "trinidadAndTobago"
                                    },
                                    {
                                        "label": "tunisia",
                                        "value": "tunisia"
                                    },
                                    {
                                        "label": "turkey",
                                        "value": "turkey"
                                    },
                                    {
                                        "label": "turkmenistan",
                                        "value": "turkmenistan"
                                    },
                                    {
                                        "label": "tuvalu",
                                        "value": "tuvalu"
                                    },
                                    {
                                        "label": "uganda",
                                        "value": "uganda"
                                    },
                                    {
                                        "label": "ukraine",
                                        "value": "ukraine"
                                    },
                                    {
                                        "label": "unitedArabEmirates",
                                        "value": "unitedArabEmirates"
                                    },
                                    {
                                        "label": "unitedKingdom",
                                        "value": "unitedKingdom"
                                    },
                                    {
                                        "label": "unitedStatesOfAmerica",
                                        "value": "unitedStatesOfAmerica"
                                    },
                                    {
                                        "label": "uruguay",
                                        "value": "uruguay"
                                    },
                                    {
                                        "label": "uzbekistan",
                                        "value": "uzbekistan"
                                    },
                                    {
                                        "label": "vanuatu",
                                        "value": "vanuatu"
                                    },
                                    {
                                        "label": "vaticanCity",
                                        "value": "vaticanCity"
                                    },
                                    {
                                        "label": "venezuela",
                                        "value": "venezuela"
                                    },
                                    {
                                        "label": "vietnam",
                                        "value": "vietnam"
                                    },
                                    {
                                        "label": "yemen",
                                        "value": "yemen"
                                    },
                                    {
                                        "label": "zambia",
                                        "value": "zambia"
                                    },
                                    {
                                        "label": "zimbabwe",
                                        "value": "zimbabwe"
                                    }
                                ],
                                "className": "col-3",
                                "icon": "faInputText"
                            },
                            "buyerCommissionValue": {
                                "type": "number",
                                "className": "col-3",
                                "icon": "faMoneyBill"
                            },
                            "buyerCommissionPercentage": {
                                "type": "number",
                                "className": "col-3",
                                "icon": "faPercent"
                            },
                            "buyerAgents": {
                                "type": "relationship",
                                "relation": {
                                    "drawerRoute": "employments",
                                    "filter": {
                                        "items": [
                                            {
                                                "field": "role",
                                                "operator": "equals",
                                                "value": "Agent"
                                            }
                                        ]
                                    },
                                    "limit": 1000
                                },
                                "view": "employment"
                            }
                        }
                    },
                    {
                        "name": "seller",
                        "fields": {
                            "sellerName": {
                                "type": "text",
                                "className": "col-3"
                            },
                            "sellerNationality": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "afghanistan",
                                        "value": "afghanistan"
                                    },
                                    {
                                        "label": "albania",
                                        "value": "albania"
                                    },
                                    {
                                        "label": "algeria",
                                        "value": "algeria"
                                    },
                                    {
                                        "label": "andorra",
                                        "value": "andorra"
                                    },
                                    {
                                        "label": "angola",
                                        "value": "angola"
                                    },
                                    {
                                        "label": "antiguaAndBarbuda",
                                        "value": "antiguaAndBarbuda"
                                    },
                                    {
                                        "label": "argentina",
                                        "value": "argentina"
                                    },
                                    {
                                        "label": "armenia",
                                        "value": "armenia"
                                    },
                                    {
                                        "label": "australia",
                                        "value": "australia"
                                    },
                                    {
                                        "label": "austria",
                                        "value": "austria"
                                    },
                                    {
                                        "label": "azerbaijan",
                                        "value": "azerbaijan"
                                    },
                                    {
                                        "label": "bahamas",
                                        "value": "bahamas"
                                    },
                                    {
                                        "label": "bahrain",
                                        "value": "bahrain"
                                    },
                                    {
                                        "label": "bangladesh",
                                        "value": "bangladesh"
                                    },
                                    {
                                        "label": "barbados",
                                        "value": "barbados"
                                    },
                                    {
                                        "label": "belarus",
                                        "value": "belarus"
                                    },
                                    {
                                        "label": "belgium",
                                        "value": "belgium"
                                    },
                                    {
                                        "label": "belize",
                                        "value": "belize"
                                    },
                                    {
                                        "label": "benin",
                                        "value": "benin"
                                    },
                                    {
                                        "label": "bhutan",
                                        "value": "bhutan"
                                    },
                                    {
                                        "label": "bolivia",
                                        "value": "bolivia"
                                    },
                                    {
                                        "label": "bosniaAndHerzegovina",
                                        "value": "bosniaAndHerzegovina"
                                    },
                                    {
                                        "label": "botswana",
                                        "value": "botswana"
                                    },
                                    {
                                        "label": "brazil",
                                        "value": "brazil"
                                    },
                                    {
                                        "label": "brunei",
                                        "value": "brunei"
                                    },
                                    {
                                        "label": "bulgaria",
                                        "value": "bulgaria"
                                    },
                                    {
                                        "label": "burkinaFaso",
                                        "value": "burkinaFaso"
                                    },
                                    {
                                        "label": "burundi",
                                        "value": "burundi"
                                    },
                                    {
                                        "label": "caboVerde",
                                        "value": "caboVerde"
                                    },
                                    {
                                        "label": "cambodia",
                                        "value": "cambodia"
                                    },
                                    {
                                        "label": "cameroon",
                                        "value": "cameroon"
                                    },
                                    {
                                        "label": "canada",
                                        "value": "canada"
                                    },
                                    {
                                        "label": "centralAfricanRepublic",
                                        "value": "centralAfricanRepublic"
                                    },
                                    {
                                        "label": "chad",
                                        "value": "chad"
                                    },
                                    {
                                        "label": "chile",
                                        "value": "chile"
                                    },
                                    {
                                        "label": "china",
                                        "value": "china"
                                    },
                                    {
                                        "label": "colombia",
                                        "value": "colombia"
                                    },
                                    {
                                        "label": "comoros",
                                        "value": "comoros"
                                    },
                                    {
                                        "label": "congo",
                                        "value": "congo"
                                    },
                                    {
                                        "label": "democraticRepublicOfTheCongo",
                                        "value": "democraticRepublicOfTheCongo"
                                    },
                                    {
                                        "label": "costaRica",
                                        "value": "costaRica"
                                    },
                                    {
                                        "label": "coteDIvoire",
                                        "value": "coteDIvoire"
                                    },
                                    {
                                        "label": "croatia",
                                        "value": "croatia"
                                    },
                                    {
                                        "label": "cuba",
                                        "value": "cuba"
                                    },
                                    {
                                        "label": "cyprus",
                                        "value": "cyprus"
                                    },
                                    {
                                        "label": "czechRepublic",
                                        "value": "czechRepublic"
                                    },
                                    {
                                        "label": "denmark",
                                        "value": "denmark"
                                    },
                                    {
                                        "label": "djibouti",
                                        "value": "djibouti"
                                    },
                                    {
                                        "label": "dominica",
                                        "value": "dominica"
                                    },
                                    {
                                        "label": "dominicanRepublic",
                                        "value": "dominicanRepublic"
                                    },
                                    {
                                        "label": "ecuador",
                                        "value": "ecuador"
                                    },
                                    {
                                        "label": "egypt",
                                        "value": "egypt"
                                    },
                                    {
                                        "label": "elSalvador",
                                        "value": "elSalvador"
                                    },
                                    {
                                        "label": "equatorialGuinea",
                                        "value": "equatorialGuinea"
                                    },
                                    {
                                        "label": "eritrea",
                                        "value": "eritrea"
                                    },
                                    {
                                        "label": "estonia",
                                        "value": "estonia"
                                    },
                                    {
                                        "label": "eswatini",
                                        "value": "eswatini"
                                    },
                                    {
                                        "label": "ethiopia",
                                        "value": "ethiopia"
                                    },
                                    {
                                        "label": "fiji",
                                        "value": "fiji"
                                    },
                                    {
                                        "label": "finland",
                                        "value": "finland"
                                    },
                                    {
                                        "label": "france",
                                        "value": "france"
                                    },
                                    {
                                        "label": "gabon",
                                        "value": "gabon"
                                    },
                                    {
                                        "label": "gambia",
                                        "value": "gambia"
                                    },
                                    {
                                        "label": "georgia",
                                        "value": "georgia"
                                    },
                                    {
                                        "label": "germany",
                                        "value": "germany"
                                    },
                                    {
                                        "label": "ghana",
                                        "value": "ghana"
                                    },
                                    {
                                        "label": "greece",
                                        "value": "greece"
                                    },
                                    {
                                        "label": "grenada",
                                        "value": "grenada"
                                    },
                                    {
                                        "label": "guatemala",
                                        "value": "guatemala"
                                    },
                                    {
                                        "label": "guinea",
                                        "value": "guinea"
                                    },
                                    {
                                        "label": "guineaBissau",
                                        "value": "guineaBissau"
                                    },
                                    {
                                        "label": "guyana",
                                        "value": "guyana"
                                    },
                                    {
                                        "label": "haiti",
                                        "value": "haiti"
                                    },
                                    {
                                        "label": "honduras",
                                        "value": "honduras"
                                    },
                                    {
                                        "label": "hungary",
                                        "value": "hungary"
                                    },
                                    {
                                        "label": "iceland",
                                        "value": "iceland"
                                    },
                                    {
                                        "label": "india",
                                        "value": "india"
                                    },
                                    {
                                        "label": "indonesia",
                                        "value": "indonesia"
                                    },
                                    {
                                        "label": "iran",
                                        "value": "iran"
                                    },
                                    {
                                        "label": "iraq",
                                        "value": "iraq"
                                    },
                                    {
                                        "label": "ireland",
                                        "value": "ireland"
                                    },
                                    {
                                        "label": "israel",
                                        "value": "israel"
                                    },
                                    {
                                        "label": "italy",
                                        "value": "italy"
                                    },
                                    {
                                        "label": "jamaica",
                                        "value": "jamaica"
                                    },
                                    {
                                        "label": "japan",
                                        "value": "japan"
                                    },
                                    {
                                        "label": "jordan",
                                        "value": "jordan"
                                    },
                                    {
                                        "label": "kazakhstan",
                                        "value": "kazakhstan"
                                    },
                                    {
                                        "label": "kenya",
                                        "value": "kenya"
                                    },
                                    {
                                        "label": "kiribati",
                                        "value": "kiribati"
                                    },
                                    {
                                        "label": "koreaNorth",
                                        "value": "koreaNorth"
                                    },
                                    {
                                        "label": "koreaSouth",
                                        "value": "koreaSouth"
                                    },
                                    {
                                        "label": "kuwait",
                                        "value": "kuwait"
                                    },
                                    {
                                        "label": "kyrgyzstan",
                                        "value": "kyrgyzstan"
                                    },
                                    {
                                        "label": "laos",
                                        "value": "laos"
                                    },
                                    {
                                        "label": "latvia",
                                        "value": "latvia"
                                    },
                                    {
                                        "label": "lebanon",
                                        "value": "lebanon"
                                    },
                                    {
                                        "label": "lesotho",
                                        "value": "lesotho"
                                    },
                                    {
                                        "label": "liberia",
                                        "value": "liberia"
                                    },
                                    {
                                        "label": "libya",
                                        "value": "libya"
                                    },
                                    {
                                        "label": "liechtenstein",
                                        "value": "liechtenstein"
                                    },
                                    {
                                        "label": "lithuania",
                                        "value": "lithuania"
                                    },
                                    {
                                        "label": "luxembourg",
                                        "value": "luxembourg"
                                    },
                                    {
                                        "label": "madagascar",
                                        "value": "madagascar"
                                    },
                                    {
                                        "label": "malawi",
                                        "value": "malawi"
                                    },
                                    {
                                        "label": "malaysia",
                                        "value": "malaysia"
                                    },
                                    {
                                        "label": "maldives",
                                        "value": "maldives"
                                    },
                                    {
                                        "label": "mali",
                                        "value": "mali"
                                    },
                                    {
                                        "label": "malta",
                                        "value": "malta"
                                    },
                                    {
                                        "label": "marshallIslands",
                                        "value": "marshallIslands"
                                    },
                                    {
                                        "label": "mauritania",
                                        "value": "mauritania"
                                    },
                                    {
                                        "label": "mauritius",
                                        "value": "mauritius"
                                    },
                                    {
                                        "label": "mexico",
                                        "value": "mexico"
                                    },
                                    {
                                        "label": "micronesia",
                                        "value": "micronesia"
                                    },
                                    {
                                        "label": "moldova",
                                        "value": "moldova"
                                    },
                                    {
                                        "label": "monaco",
                                        "value": "monaco"
                                    },
                                    {
                                        "label": "mongolia",
                                        "value": "mongolia"
                                    },
                                    {
                                        "label": "montenegro",
                                        "value": "montenegro"
                                    },
                                    {
                                        "label": "morocco",
                                        "value": "morocco"
                                    },
                                    {
                                        "label": "mozambique",
                                        "value": "mozambique"
                                    },
                                    {
                                        "label": "myanmar",
                                        "value": "myanmar"
                                    },
                                    {
                                        "label": "namibia",
                                        "value": "namibia"
                                    },
                                    {
                                        "label": "nauru",
                                        "value": "nauru"
                                    },
                                    {
                                        "label": "nepal",
                                        "value": "nepal"
                                    },
                                    {
                                        "label": "netherlands",
                                        "value": "netherlands"
                                    },
                                    {
                                        "label": "newZealand",
                                        "value": "newZealand"
                                    },
                                    {
                                        "label": "nicaragua",
                                        "value": "nicaragua"
                                    },
                                    {
                                        "label": "niger",
                                        "value": "niger"
                                    },
                                    {
                                        "label": "nigeria",
                                        "value": "nigeria"
                                    },
                                    {
                                        "label": "northMacedonia",
                                        "value": "northMacedonia"
                                    },
                                    {
                                        "label": "norway",
                                        "value": "norway"
                                    },
                                    {
                                        "label": "oman",
                                        "value": "oman"
                                    },
                                    {
                                        "label": "pakistan",
                                        "value": "pakistan"
                                    },
                                    {
                                        "label": "palau",
                                        "value": "palau"
                                    },
                                    {
                                        "label": "panama",
                                        "value": "panama"
                                    },
                                    {
                                        "label": "papuaNewGuinea",
                                        "value": "papuaNewGuinea"
                                    },
                                    {
                                        "label": "paraguay",
                                        "value": "paraguay"
                                    },
                                    {
                                        "label": "peru",
                                        "value": "peru"
                                    },
                                    {
                                        "label": "philippines",
                                        "value": "philippines"
                                    },
                                    {
                                        "label": "poland",
                                        "value": "poland"
                                    },
                                    {
                                        "label": "portugal",
                                        "value": "portugal"
                                    },
                                    {
                                        "label": "qatar",
                                        "value": "qatar"
                                    },
                                    {
                                        "label": "romania",
                                        "value": "romania"
                                    },
                                    {
                                        "label": "russia",
                                        "value": "russia"
                                    },
                                    {
                                        "label": "rwanda",
                                        "value": "rwanda"
                                    },
                                    {
                                        "label": "saintKittsAndNevis",
                                        "value": "saintKittsAndNevis"
                                    },
                                    {
                                        "label": "saintLucia",
                                        "value": "saintLucia"
                                    },
                                    {
                                        "label": "saintVincentAndTheGrenadines",
                                        "value": "saintVincentAndTheGrenadines"
                                    },
                                    {
                                        "label": "samoa",
                                        "value": "samoa"
                                    },
                                    {
                                        "label": "sanMarino",
                                        "value": "sanMarino"
                                    },
                                    {
                                        "label": "saoTomeAndPrincipe",
                                        "value": "saoTomeAndPrincipe"
                                    },
                                    {
                                        "label": "saudiArabia",
                                        "value": "saudiArabia"
                                    },
                                    {
                                        "label": "senegal",
                                        "value": "senegal"
                                    },
                                    {
                                        "label": "serbia",
                                        "value": "serbia"
                                    },
                                    {
                                        "label": "seychelles",
                                        "value": "seychelles"
                                    },
                                    {
                                        "label": "sierraLeone",
                                        "value": "sierraLeone"
                                    },
                                    {
                                        "label": "singapore",
                                        "value": "singapore"
                                    },
                                    {
                                        "label": "slovakia",
                                        "value": "slovakia"
                                    },
                                    {
                                        "label": "slovenia",
                                        "value": "slovenia"
                                    },
                                    {
                                        "label": "solomonIslands",
                                        "value": "solomonIslands"
                                    },
                                    {
                                        "label": "somalia",
                                        "value": "somalia"
                                    },
                                    {
                                        "label": "southAfrica",
                                        "value": "southAfrica"
                                    },
                                    {
                                        "label": "southSudan",
                                        "value": "southSudan"
                                    },
                                    {
                                        "label": "spain",
                                        "value": "spain"
                                    },
                                    {
                                        "label": "sriLanka",
                                        "value": "sriLanka"
                                    },
                                    {
                                        "label": "sudan",
                                        "value": "sudan"
                                    },
                                    {
                                        "label": "suriname",
                                        "value": "suriname"
                                    },
                                    {
                                        "label": "sweden",
                                        "value": "sweden"
                                    },
                                    {
                                        "label": "switzerland",
                                        "value": "switzerland"
                                    },
                                    {
                                        "label": "syria",
                                        "value": "syria"
                                    },
                                    {
                                        "label": "taiwan",
                                        "value": "taiwan"
                                    },
                                    {
                                        "label": "tajikistan",
                                        "value": "tajikistan"
                                    },
                                    {
                                        "label": "tanzania",
                                        "value": "tanzania"
                                    },
                                    {
                                        "label": "thailand",
                                        "value": "thailand"
                                    },
                                    {
                                        "label": "timorLeste",
                                        "value": "timorLeste"
                                    },
                                    {
                                        "label": "togo",
                                        "value": "togo"
                                    },
                                    {
                                        "label": "tonga",
                                        "value": "tonga"
                                    },
                                    {
                                        "label": "trinidadAndTobago",
                                        "value": "trinidadAndTobago"
                                    },
                                    {
                                        "label": "tunisia",
                                        "value": "tunisia"
                                    },
                                    {
                                        "label": "turkey",
                                        "value": "turkey"
                                    },
                                    {
                                        "label": "turkmenistan",
                                        "value": "turkmenistan"
                                    },
                                    {
                                        "label": "tuvalu",
                                        "value": "tuvalu"
                                    },
                                    {
                                        "label": "uganda",
                                        "value": "uganda"
                                    },
                                    {
                                        "label": "ukraine",
                                        "value": "ukraine"
                                    },
                                    {
                                        "label": "unitedArabEmirates",
                                        "value": "unitedArabEmirates"
                                    },
                                    {
                                        "label": "unitedKingdom",
                                        "value": "unitedKingdom"
                                    },
                                    {
                                        "label": "unitedStatesOfAmerica",
                                        "value": "unitedStatesOfAmerica"
                                    },
                                    {
                                        "label": "uruguay",
                                        "value": "uruguay"
                                    },
                                    {
                                        "label": "uzbekistan",
                                        "value": "uzbekistan"
                                    },
                                    {
                                        "label": "vanuatu",
                                        "value": "vanuatu"
                                    },
                                    {
                                        "label": "vaticanCity",
                                        "value": "vaticanCity"
                                    },
                                    {
                                        "label": "venezuela",
                                        "value": "venezuela"
                                    },
                                    {
                                        "label": "vietnam",
                                        "value": "vietnam"
                                    },
                                    {
                                        "label": "yemen",
                                        "value": "yemen"
                                    },
                                    {
                                        "label": "zambia",
                                        "value": "zambia"
                                    },
                                    {
                                        "label": "zimbabwe",
                                        "value": "zimbabwe"
                                    }
                                ],
                                "className": "col-3",
                                "icon": "faInputText"
                            },
                            "sellerCommissionValue": {
                                "type": "number",
                                "className": "col-3",
                                "icon": "faMoneyBill"
                            },
                            "sellerCommissionPercentage": {
                                "type": "number",
                                "className": "col-3",
                                "icon": "faPercent"
                            },
                            "sellerAgents": {
                                "type": "relationship",
                                "relation": {
                                    "drawerRoute": "employments",
                                    "filter": {
                                        "items": [
                                            {
                                                "field": "role",
                                                "operator": "equals",
                                                "value": "Agent"
                                            }
                                        ]
                                    },
                                    "limit": 1000
                                },
                                "view": "employment"
                            }
                        }
                    }
                ]
            }
        ],
        "validation": []
    }
    useEffect(() => {
        loadOne(params.filters?._id);
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])


    const handleSave = async (data) => {
        doAction && doAction({ action: wizardAction, model: model ? model : dataProviderName, data: data });
    }

    const values = {
        id: item?.data._id,

        legalEntity: params.legalEntity,
        employment: params.employment,
        registered_at: new Date().toISOString(),
        propertyID: 'W-',
        status: 'in_progress',
        product: 'buy',

    }
    // injectToDetail(item?.data, 'legalEntity', params.legalEntity);
    // injectToDetail(item?.data, 'employment', params.employment);
    // injectToDetail(item?.data, 'registered_at', new Date().toISOString());
    // injectToDetail(item?.data, 'propertyID', 'W-');
    // injectToDetail(item?.data, 'status', 'in_progress');
    // injectToDetail(item?.data, 'product', 'buy');

    console.info('ITME DATA IN CREATE', item?.data);
    console.info('VALUE DATA IN CREATE', values);
    return <>
        <DetailComponent
            values={values}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('New {{itemname}}', { itemname: t(modelExposedName) })}
            canSave={userCapabilities && userCapabilities[dataProviderName + '.create']}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Create new {{itemname}}', { itemname: t(modelExposedName) })}
                    subtitle={t('Fill the basic information to create a new {{itemname}} that you will be able to edit via the standard interface', { itemname: t(modelExposedName) })}
                />
            }
            {...params}
        />
    </>
}

export default Create;


