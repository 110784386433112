import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/contract';
const wizardName = 'Contract Extension';
const wizardObject = 'Contract';
const wizardAction = 'contractExtension';

const ContractExtension = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);

    // DATA PROVIDER
    const { doAction, item, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);

    const { modelName } = existingModules

    const detailConfig = {
        "tabs": [
            {
                "name": "contract_extension",
                "fieldsets": [
                    {
                        "name": "savings",
                        "fields": {
                            "savings": {
                                "type": "savingsWidget",
                                "className": "col-12",
                                "item":item?.data
                            }
                        }
                    },
                    {
                        "name": "contract_extension",
                        "fields": {
                            "contract_extension": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "contractReajustmentStart_at": {
                                "type": "date",
                                "className": "col-12",
                                "icon": "faCalendarHeart"
                            },
                            "end_at": {
                                "type": "date",
                                "className": "col-6",
                                "icon": "faCalendarXmark"
                            },
                            "contractMileage": {
                                "type": "number",
                                "className": "col-6",
                                "icon": "faRoad"
                            },
                        }
                    },
                    {
                        "name": "fees",
                        "fields": {
                            "financialFee": {
                                "type": "number",
                                "className": "col-6",
                                "icon": "faMoneyBill"
                            },
                            "serviceFee": {
                                "type": "number",
                                "className": "col-6",
                                "icon": "faMoneyBill"
                            },
                            "fuelFee": {
                                "type": "number",
                                "className": "col-6",
                                "icon": "faMoneyBill"
                            },
                            "fuelIncluded": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "yes",
                                        "value": "yes"
                                    },
                                    {
                                        "label": "no",
                                        "value": "no"
                                    }
                                ],
                                "className": "col-6",
                                "icon": "faToggleOn"
                            },
                            "insuranceFee": {
                                "type": "number",
                                "className": "col-6",
                                "icon": "faMoneyBill"
                            },
                            "insuranceIncluded": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "yes",
                                        "value": "yes"
                                    },
                                    {
                                        "label": "no",
                                        "value": "no"
                                    }
                                ],
                                "className": "col-6",
                                "icon": "faToggleOn"
                            },
                            "taxFee": {
                                "type": "number",
                                "className": "col-6",
                                "icon": "faMoneyBill"
                            },
                            "taxIncluded": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "yes",
                                        "value": "yes"
                                    },
                                    {
                                        "label": "no",
                                        "value": "no"
                                    }
                                ],
                                "className": "col-6",
                                "icon": "faToggleOn"
                            },
                            "totalLeaseFee": {
                                "type": "number",
                                "className": "col-12",
                                "icon": "faMoneyBill"
                            }
                        }
                    }
                    /*
                    Data Effetto		
                    Nuova Data Fine Prorogata		
                    Nuovi KM a fronte Proroga		
                    Data Fine = Nuova Data Fine Prorogata			
                    Canone
                    */
                ]
            }
        ],
        "validation": []
    }
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])


    const handleSave = async (data) => {
        doAction && await doAction({ action: wizardAction, model: dataProviderName, data: data });
        close();
    }

    return <>
        <DetailComponent
            values={{
                id: item?.data?._id,
                contractReajustmentStart_at: new Date().toISOString(),
                end_at: item?.data?.end_at,
                contractMileage: item?.data?.contractMileage,
                financialFee: item?.data?.financialFee,
                serviceFee: item?.data?.serviceFee,
                fuelFee: item?.data?.fuelFee,
                fuelIncluded: item?.data?.fuelIncluded,
                insuranceFee: item?.data?.insuranceFee,
                insuranceIncluded: item?.data?.insuranceIncluded,
                taxFee: item?.data?.taxFee,
                taxIncluded: item?.data?.taxIncluded,
            }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName + '.' + wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Extend {{wizardObject}} {{identifier}}', { wizardObject: wizardObject, identifier: item.data.contractIdentifier })}
                />
            }
            {...params}
        />
    </>
}

export default ContractExtension;


