import React, { useEffect, useState } from "react";
import { useDataProvider } from "src/mtska-frontend-data-provider";
import { Box, Table } from '@mui/joy';
import { useTranslation } from 'react-i18next';

const WidgetSavings = ({ editing, item, readonly }) => {
    const { t } = useTranslation();
    //const { item: costbreakdown, loadOne } = useDataProvider('costbreakdown/supplier')

    const [timeFrame, setTimeFrame] = useState('0');
    const [isDrawable, setIsDrawable] = useState(false);

    // useEffect(() => {
    //     if (typeof item.id == 'undefined') return;
    //     loadOne(item.id + '/' + timeFrame);
    // }, [item, timeFrame]);

    // useEffect(() => {
    //     setIsDrawable(true)
    // }, [costbreakdown]);

    return (
        <Box className="form-element form-element-savings col-12">
            car
            <textarea>{ JSON.stringify(item?.car) }</textarea>
            traceKmsLast
            <textarea>{ JSON.stringify(item?.car?.traceKmsLast) }</textarea>
        </Box>
    );
}

export default WidgetSavings;