import React from "react";
import { Box } from "@mui/joy";

const VehicleAvatar = ({ size = "default", url = null, withname = false, fullname = null, detail = null }) => {

    return (
        <Box className={"vehicle-avatar-wrapper vehicle-avatar-withname-" + withname}>
            <Box className={"vehicle-avatar vehicle-avatar-" + size}>
                {url && (
                    <img src={url} />
                )}
                {!url && (

                    <img src={'/images/car.png'} className="default" />

                )}
            </Box>
            {withname === true && (
                <Box className={"vehicle-name"+(detail && " hasdetail")}>
                    <span className="fullname" title={fullname}>{fullname}</span>
                    {
                        detail && (
                            <span className="detail">{(detail)}</span>
                        )
                    }
                </Box>
            )}
        </Box>
    )
}

export default VehicleAvatar;