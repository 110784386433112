import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';
import { useFormatter } from 'src/mtska-frontend-app-component/utils';
import { useTranslation } from 'react-i18next'

const RelationshipFringeBenefitValue = ({ k, v, editing, detailInspector = true, handleItemDetail, handleItemRemove }) => {
    const { t } = useTranslation();
    const { formatCurrency } = useFormatter();

    const getType = (v) => {
        return v?.type ? v?.type.toLowerCase() : '';
    }

    const getStatus = (v) => {
        return v?.status ? v?.status.toLowerCase() : 'Running';
    }

    return v ? (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content vehicle-type-" + getType(v) + " vehicle-status-" + getStatus(v)}>
                <Box className="year coupled">
                    <Box className="label">{t('Year')}</Box>
                    <Box className="value">{v?.year}</Box>
                </Box>
                <Box className="costkm coupled">
                    <Box className="label">{t('costKm')}</Box>
                    <Box className="value">{formatCurrency(v?.costKm)}</Box>
                </Box>
                <Box className="value coupled">
                    <Box className="label">{t('value25')}</Box>
                    <Box className="value">{formatCurrency(v?.value25)}</Box>
                </Box>
                <Box className="value coupled">
                    <Box className="label">{t('value30')}</Box>
                    <Box className="value">{formatCurrency(v?.value30)}</Box>
                </Box>
                <Box className="value coupled">
                    <Box className="label">{t('value50')}</Box>
                    <Box className="value">{formatCurrency(v?.value50)}</Box>
                </Box>
                <Box className="value coupled">
                    <Box className="label">{t('value60')}</Box>
                    <Box className="value">{formatCurrency(v?.value60)}</Box>
                </Box>
            </ListItemContent>

            {editing && detailInspector && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    ) : (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content vehicle-type-" + getType(v) + " vehicle-status-" + getStatus(v)}>
                non assegnato
            </ListItemContent>
        </ListItem>
    )
}


export default RelationshipFringeBenefitValue


