import React from "react"
import { useEffect, useState } from "react"

import { Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent } from "../../components";
import { useDataProvider } from "../../../mtska-frontend-data-provider";


const dataProviderName = 'persons/employment';

const ViewEmploymentDetail = (params) => {

    const { editing, item, required, name, control, label, autoFocus, placeholder, type = "text", hint, icon, haserror, disabled, readonly, className = "" } = params;

    const { t } = useTranslation();
    if (!editing) readonly = true;

    const { item: config } = useDataProvider('configs/config');

    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        setDetailConfig(config.data.modules[dataProviderName].detailConfig);
        setModelName(config.data.modules[dataProviderName].modelName);
    }, [config, dataProviderName]);

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value, name },
                fieldState: { error, invalid },
                formState,
            }) => {


                return <>
                    <div className=" form-element col-12">
                        <DetailComponent
                            {...params}
                            readonly
                            values={value}
                            detailConfig={detailConfig}
                            rightButtons={[]} />
                    </div>

                </>
            }}
        />
    );
}

export default ViewEmploymentDetail



