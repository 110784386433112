import React from 'react';
import { Box } from '@mui/joy';
import { useFormatter } from '../../utils';

const CellPercentage = ({ value, type }) => {
    const { formatPercentage } = useFormatter();
    
    return <Box className={"list-element-cell cell-" + type}>{ (value) ? formatPercentage(value) : '' }</Box>
}

export default CellPercentage