import React from "react";
import { Box } from "@mui/joy";
import { useString } from "../../utils/String";
import { Icon } from "../../assets";
import { Tag } from ".";

const LegalEntityAvatar = ({ name, size = "default", url = null, onClick, withname = false, detail = false, error = false }) => {

    const { firstCharacter, toColor } = useString();

    return (
        <Box className={"company-avatar-wrapper company-avatar-withname-" + withname + ((error) ? ' company-avatar-error' : '')} onClick={onClick}>
            <Box className={"company-avatar company-avatar-" + size} style={{backgroundColor:url ? 'white' : (error ? 'var(--danger-400)' : toColor(name+name))}}>
                {(url) && (
                    <img src={url} />
                )}
                {!url && !error && (
                    <span className="name" title={name}>{firstCharacter(name)}</span>
                )}
                {!url && error && (
                    <span className="name" title={name}><Icon icon={"faWarning"} /></span>
                )}
            </Box>
            {(withname && !error) && (
                <Box className={"company-name"+(detail ? ' hasdetail' : '')}>
                    <span className="fullname" title={name}>{(name)}</span>
                    {
                        detail && (
                            <span className="detail">{(detail)}</span>
                        )
                    }
                </Box>
            )}
            {(withname && error) && (
                <Tag color={"danger"} title={error}><Icon icon="faWarning" />{error}</Tag>
            )}
        </Box>
    )
}

export default LegalEntityAvatar;