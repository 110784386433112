import React, {useMemo} from 'react';
import { Box } from '@mui/joy';
import { KPI } from '../../../../mtska-frontend-app-component';
import List from './List';

const Default = () => {

    return useMemo(() => <>
        <Box className="mtksa-chart-wrapper">
            <KPI
                title="Cost Centers"
                icon="faBagShopping"
                dataProviderName={'statistics/total/count/companies/costCenter'}
            />
            <KPI
                title="Legal Entities"
                icon="faBuilding"
                dataProviderName={'statistics/total/count/companies/legalEntity'}
                filters={{
                    'items': [{
                        'field': 'type',
                        'operator': 'isNot',
                        'value': 'holding'
                    }]
                }}
            />
        </Box>
        <List />
    </>,[])

}

export default Default;


